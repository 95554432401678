import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';

//---helpers
import { PRODUCTS_ORDER_KEY } from '@/helpers';
import { globalLoader, pluralizate } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import ProductsBlock from '@/components/products/ProductsBlock/index.vue';
import Pagination from '@/components/@general/Pagination/index.vue';
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';

export default {
  name: 'Series',
  mixins: [dynamicHead],
  components: {
    BreadcrumbsSimple,
    ProductsBlock,
    Pagination
  },
  data() {
    return {
      seriesData: null,
      seriesProducts: [],
      seriesProductsMeta: null,
      isSeriesProductsLoading: false
    };
  },
  created() {
    this._loadContent();
  },
  watch: {
    $route: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this._getProducts();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList'
    }),
    breadcrumbs() {
      return [
        { title: this.$t('catalog'), route: { name: 'products' } },
        { title: this.$t('product'), route: { name: 'products' } }
      ];
    },
    titlePluralizate() {
      return pluralizate(
        this.seriesProductsMeta ? this.seriesProductsMeta.total : 0,
        this.$t('label.products.countOne'),
        this.$t('label.products.countSimple'),
        this.$t('label.products.countMany')
      );
    },
    paginationRootPath() {
      let { brand, series } = this.$route.params;
      let paramsString = brand ? `${brand}/${series}` : series;
      return `product-category/${paramsString}`;
    },
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.seriesProducts;
      }

      const favourites = _.map(this.favourites, 'productId');
      const markAsSelected = (product) => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      return _.map(this.seriesProducts, markAsSelected);
    }
  },
  methods: {
    ...mapActions({
      getCatalogCategory: 'catalog/GET_CATALOG_CATEGORY',
      getCatalogProducts: 'catalog/GET_CATALOG_PRODUCTS'
    }),
    ...mapMutations({}),
    _getProducts() {
      this.isSeriesProductsLoading = true;
      let { params, query } = this.$route;
      let { series, page } = params;
      let { orderby } = query;

      return this.getCatalogProducts({
        brand_slug: series,
        page: page ? Number(page) : 1,
        sort_filter_type: orderby ? orderby : PRODUCTS_ORDER_KEY.BESTSELLERS
      })
        .then((result) => {
          this.seriesProducts = result.data;
          this.seriesProductsMeta = result.meta;
        })
        .finally(() => {
          this.isSeriesProductsLoading = false;
        });
    },
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      let { series } = this.$route.params;
      promiseCollection.push(this.getCatalogCategory(series).then((result) => (this.seriesData = result.data)));
      promiseCollection.push(this._getProducts());
      await Promise.all(promiseCollection);
      this._setMetaParams();

      globalLoader(false);
    },
    _setMetaParams() {
      console.log('route', this.$route);
      let { metaTitle, originalTitle, metaDescription, image } = this.seriesData;
      if (originalTitle || metaTitle)
        this.metaParams.title = `${metaTitle ? metaTitle : originalTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
